.product-carousel {
  width: 100%;
  height: 100%;
}

.product-carousel .swiper-slide {
  height: 100%;
  width: auto !important; /* Otomatik genişlik için */
}

/* Her slide'ın içeriğinin tam yükseklikte olması için */
.swiper-slide > a {
  display: block;
  height: 100%;
}

.swiper-slide > a > div {
  height: 100%;
  width: auto;
  aspect-ratio: 3/4; /* Oranı korumak için */
}

/* Responsive ayarlamalar */
@media (max-width: 768px) {
  .product-carousel {
    padding: 0 1rem;
  }
}

/* Smooth scrolling için */
.swiper-wrapper {
  transition-timing-function: linear;
} 