/* Default Navbar */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1rem;
  font-size: small;
}
.nav-link {
  text-decoration: none;
  color: #d18974;
  border-right: #000000; /* Black */
  border-right-width: 1px;
  padding: 1.2rem 2rem; /* Adjusted padding */
  transition: color 0.3s ease, background-color 0.3s ease, margin 0.3s ease,
    box-shadow 0.3s ease; /* Smooth transition for color, background-color, margin, and box-shadow changes */
}

/* Change color, background-color, padding, and add box-shadow to navbar items on hover */
.nav-link:hover {
  color: #ffffff; /* White */
  background-color: #d18974; /* Light Gray */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box-shadow on hover */
}

/* Logo */
.logo {
  height: 40px;
  margin-right: 1rem;
}

/* Fiyat göstergeleri için animasyon */
@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

.bg-white\/10 {
  transition: all 0.3s ease;
}

.bg-white\/10:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

/* Responsive tasarım için */
@media (max-width: 640px) {
  .container .flex {
    justify-content: center;
    padding: 0.5rem;
  }
  
  .bg-white\/10 {
    padding: 0.5rem 1rem;
  }
  
  .text-sm {
    font-size: 0.75rem;
  }
}
